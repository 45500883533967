import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f051ff9a = () => interopDefault(import('../sites/erossk/pages/user/activate.vue' /* webpackChunkName: "sites/erossk/pages/user/activate" */))
const _070ff905 = () => interopDefault(import('../sites/erossk/pages/authorsList/index.vue' /* webpackChunkName: "sites/erossk/pages/authorsList/index" */))
const _16d1bea8 = () => interopDefault(import('../sites/erossk/pages/blocksettings.vue' /* webpackChunkName: "sites/erossk/pages/blocksettings" */))
const _52df1667 = () => interopDefault(import('../sites/erossk/pages/promotions/index.vue' /* webpackChunkName: "sites/erossk/pages/promotions/index" */))
const _27a92ca3 = () => interopDefault(import('../sites/erossk/pages/term/index.vue' /* webpackChunkName: "sites/erossk/pages/term/index" */))
const _4e8c5bdf = () => interopDefault(import('../sites/erossk/pages/search/index.vue' /* webpackChunkName: "sites/erossk/pages/search/index" */))
const _f0a7ae96 = () => interopDefault(import('../sites/erossk/pages/category/index.vue' /* webpackChunkName: "sites/erossk/pages/category/index" */))
const _398b859f = () => interopDefault(import('../sites/erossk/pages/contact/index.vue' /* webpackChunkName: "sites/erossk/pages/contact/index" */))
const _f1ba0206 = () => interopDefault(import('../sites/erossk/pages/checkout/index.vue' /* webpackChunkName: "sites/erossk/pages/checkout/index" */))
const _df29f9d6 = () => interopDefault(import('../sites/erossk/pages/faq/index.vue' /* webpackChunkName: "sites/erossk/pages/faq/index" */))
const _195aed33 = () => interopDefault(import('../sites/erossk/pages/articleList/index.vue' /* webpackChunkName: "sites/erossk/pages/articleList/index" */))
const _29f4eb59 = () => interopDefault(import('../sites/erossk/pages/branch/index.vue' /* webpackChunkName: "sites/erossk/pages/branch/index" */))
const _faf1b01a = () => interopDefault(import('../sites/erossk/pages/shoppingGuide/index.vue' /* webpackChunkName: "sites/erossk/pages/shoppingGuide/index" */))
const _47718be5 = () => interopDefault(import('../sites/erossk/pages/login.vue' /* webpackChunkName: "sites/erossk/pages/login" */))
const _96507252 = () => interopDefault(import('../sites/erossk/pages/register.vue' /* webpackChunkName: "sites/erossk/pages/register" */))
const _0919392c = () => interopDefault(import('../sites/erossk/pages/complaint/index.vue' /* webpackChunkName: "sites/erossk/pages/complaint/index" */))
const _40d2d670 = () => interopDefault(import('../sites/erossk/pages/passwordReset.vue' /* webpackChunkName: "sites/erossk/pages/passwordReset" */))
const _2d47f5b3 = () => interopDefault(import('../sites/erossk/pages/sitemapconfig.vue' /* webpackChunkName: "sites/erossk/pages/sitemapconfig" */))
const _0806eeb4 = () => interopDefault(import('../sites/erossk/pages/fittingGuide/index.vue' /* webpackChunkName: "sites/erossk/pages/fittingGuide/index" */))
const _4a1f4f8f = () => interopDefault(import('../sites/erossk/pages/maintenance.vue' /* webpackChunkName: "sites/erossk/pages/maintenance" */))
const _cdb92d7c = () => interopDefault(import('../sites/erossk/pages/user/index.vue' /* webpackChunkName: "sites/erossk/pages/user/index" */))
const _fc831d0c = () => interopDefault(import('../sites/erossk/pages/complaintReturn/index.vue' /* webpackChunkName: "sites/erossk/pages/complaintReturn/index" */))
const _211bc6a6 = () => interopDefault(import('../sites/erossk/pages/aboutPurchase/index.vue' /* webpackChunkName: "sites/erossk/pages/aboutPurchase/index" */))
const _7457d58a = () => interopDefault(import('../sites/erossk/pages/passwordForgotten.vue' /* webpackChunkName: "sites/erossk/pages/passwordForgotten" */))
const _781b2146 = () => interopDefault(import('../sites/erossk/pages/brand/index.vue' /* webpackChunkName: "sites/erossk/pages/brand/index" */))
const _738cd553 = () => interopDefault(import('../sites/erossk/pages/checkout/info.vue' /* webpackChunkName: "sites/erossk/pages/checkout/info" */))
const _96b53ed2 = () => interopDefault(import('../sites/erossk/pages/checkout/finished.vue' /* webpackChunkName: "sites/erossk/pages/checkout/finished" */))
const _030ad91d = () => interopDefault(import('../sites/erossk/pages/checkout/methods.vue' /* webpackChunkName: "sites/erossk/pages/checkout/methods" */))
const _e894bad2 = () => interopDefault(import('../sites/erossk/pages/checkout/importOrder/index.vue' /* webpackChunkName: "sites/erossk/pages/checkout/importOrder/index" */))
const _536a6a80 = () => interopDefault(import('../sites/erossk/pages/user/shoppingLists/index.vue' /* webpackChunkName: "sites/erossk/pages/user/shoppingLists/index" */))
const _fcd2f430 = () => interopDefault(import('../sites/erossk/pages/user/orders/index.vue' /* webpackChunkName: "sites/erossk/pages/user/orders/index" */))
const _286e3aac = () => interopDefault(import('../sites/erossk/pages/user/favourites.vue' /* webpackChunkName: "sites/erossk/pages/user/favourites" */))
const _2232613a = () => interopDefault(import('../sites/erossk/pages/user/settings.vue' /* webpackChunkName: "sites/erossk/pages/user/settings" */))
const _2a2d63f8 = () => interopDefault(import('../sites/erossk/pages/user/dashboard.vue' /* webpackChunkName: "sites/erossk/pages/user/dashboard" */))
const _1e4c5c4a = () => interopDefault(import('../sites/erossk/pages/user/password.vue' /* webpackChunkName: "sites/erossk/pages/user/password" */))
const _af80895a = () => interopDefault(import('../sites/erossk/pages/checkout/payment/callback.vue' /* webpackChunkName: "sites/erossk/pages/checkout/payment/callback" */))
const _7dc9e8ce = () => interopDefault(import('../sites/erossk/pages/index.vue' /* webpackChunkName: "sites/erossk/pages/index" */))
const _578c7d28 = () => interopDefault(import('../sites/erossk/pages/user/shoppingLists/_id.vue' /* webpackChunkName: "sites/erossk/pages/user/shoppingLists/_id" */))
const _355ce250 = () => interopDefault(import('../sites/erossk/pages/user/orders/_id.vue' /* webpackChunkName: "sites/erossk/pages/user/orders/_id" */))
const _a435928c = () => interopDefault(import('../sites/erossk/pages/author/_slug.vue' /* webpackChunkName: "sites/erossk/pages/author/_slug" */))
const _17a44beb = () => interopDefault(import('../sites/erossk/pages/articleList/_slug.vue' /* webpackChunkName: "sites/erossk/pages/articleList/_slug" */))
const _25f28b5b = () => interopDefault(import('../sites/erossk/pages/term/_slug.vue' /* webpackChunkName: "sites/erossk/pages/term/_slug" */))
const _5c74cdd8 = () => interopDefault(import('../sites/erossk/pages/infomail/_slug.vue' /* webpackChunkName: "sites/erossk/pages/infomail/_slug" */))
const _0ab1f9ad = () => interopDefault(import('../sites/erossk/pages/article/_slug.vue' /* webpackChunkName: "sites/erossk/pages/article/_slug" */))
const _283e4a11 = () => interopDefault(import('../sites/erossk/pages/branch/_slug.vue' /* webpackChunkName: "sites/erossk/pages/branch/_slug" */))
const _5933d334 = () => interopDefault(import('../sites/erossk/pages/product/_slug.vue' /* webpackChunkName: "sites/erossk/pages/product/_slug" */))
const _00886628 = () => interopDefault(import('../sites/erossk/pages/curatedGuidePage/_slug.vue' /* webpackChunkName: "sites/erossk/pages/curatedGuidePage/_slug" */))
const _2eb0cc84 = () => interopDefault(import('../sites/erossk/pages/page/_slug.vue' /* webpackChunkName: "sites/erossk/pages/page/_slug" */))
const _a94df84a = () => interopDefault(import('../sites/erossk/pages/_.vue' /* webpackChunkName: "sites/erossk/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivovat",
    component: _f051ff9a,
    name: "user-activate___sk"
  }, {
    path: "/autori",
    component: _070ff905,
    name: "authorsList___sk"
  }, {
    path: "/blocksettings",
    component: _16d1bea8,
    name: "blocksettings___sk"
  }, {
    path: "/eroticke-pomocky-v-akcii-na-eros-sk",
    component: _52df1667,
    name: "promotions___sk"
  }, {
    path: "/eroticky-vykladovy-slovnik",
    component: _27a92ca3,
    name: "term___sk"
  }, {
    path: "/hladat",
    component: _4e8c5bdf,
    name: "search___sk"
  }, {
    path: "/kategorie",
    component: _f0a7ae96,
    name: "category___sk"
  }, {
    path: "/kontakt",
    component: _398b859f,
    name: "contact___sk"
  }, {
    path: "/kosik",
    component: _f1ba0206,
    name: "checkout___sk"
  }, {
    path: "/najcastejsie-dotazy",
    component: _df29f9d6,
    name: "faq___sk"
  }, {
    path: "/nakupny-radca",
    component: _195aed33,
    name: "articleList___sk"
  }, {
    path: "/pobocky",
    component: _29f4eb59,
    name: "branch___sk"
  }, {
    path: "/poradna",
    component: _faf1b01a,
    name: "shoppingGuide___sk"
  }, {
    path: "/prihlasit",
    component: _47718be5,
    name: "login___sk"
  }, {
    path: "/registrovat",
    component: _96507252,
    name: "register___sk"
  }, {
    path: "/reklamacia",
    component: _0919392c,
    name: "complaint___sk"
  }, {
    path: "/resetovat-heslo",
    component: _40d2d670,
    name: "passwordReset___sk"
  }, {
    path: "/sitemapconfig",
    component: _2d47f5b3,
    name: "sitemapconfig___sk"
  }, {
    path: "/tabulka-velkosti",
    component: _0806eeb4,
    name: "fittingGuide___sk"
  }, {
    path: "/udrzba",
    component: _4a1f4f8f,
    name: "maintenance___sk"
  }, {
    path: "/uzivatel",
    component: _cdb92d7c,
    name: "user___sk"
  }, {
    path: "/vratenie-tovaru",
    component: _fc831d0c,
    name: "complaintReturn___sk"
  }, {
    path: "/vsetko-o-nakupe",
    component: _211bc6a6,
    name: "aboutPurchase___sk"
  }, {
    path: "/zabudnute-heslo",
    component: _7457d58a,
    name: "passwordForgotten___sk"
  }, {
    path: "/znacky",
    component: _781b2146,
    name: "brand___sk"
  }, {
    path: "/kosik/dodacie-udaje",
    component: _738cd553,
    name: "checkout-info___sk"
  }, {
    path: "/kosik/dokoncenie",
    component: _96b53ed2,
    name: "checkout-finished___sk"
  }, {
    path: "/kosik/doprava-a-platba",
    component: _030ad91d,
    name: "checkout-methods___sk"
  }, {
    path: "/kosik/import-objednavky",
    component: _e894bad2,
    name: "checkout-importOrder___sk"
  }, {
    path: "/uzivatel/nakupne-zoznamy",
    component: _536a6a80,
    name: "user-shoppingLists___sk"
  }, {
    path: "/uzivatel/objednavky",
    component: _fcd2f430,
    name: "user-orders___sk"
  }, {
    path: "/uzivatel/oblubene",
    component: _286e3aac,
    name: "user-favourites___sk"
  }, {
    path: "/uzivatel/osobni-udaje",
    component: _2232613a,
    name: "user-settings___sk"
  }, {
    path: "/uzivatel/prehled",
    component: _2a2d63f8,
    name: "user-dashboard___sk"
  }, {
    path: "/uzivatel/zmena-hesla",
    component: _1e4c5c4a,
    name: "user-password___sk"
  }, {
    path: "/checkout/payment/callback",
    component: _af80895a,
    name: "checkout-payment-callback___sk"
  }, {
    path: "/",
    component: _7dc9e8ce,
    name: "index___sk"
  }, {
    path: "/uzivatel/nakupne-zoznamy/:id",
    component: _578c7d28,
    name: "user-shoppingLists-id___sk"
  }, {
    path: "/uzivatel/objednavky/:id",
    component: _355ce250,
    name: "user-orders-id___sk"
  }, {
    path: "/autori/:slug",
    component: _a435928c,
    name: "author-slug___sk"
  }, {
    path: "/clanky/:slug",
    component: _17a44beb,
    name: "articleList-slug___sk"
  }, {
    path: "/eroticky-vykladovy-slovnik/:slug",
    component: _25f28b5b,
    name: "term-slug___sk"
  }, {
    path: "/infomail/:slug?",
    component: _5c74cdd8,
    name: "infomail-slug___sk"
  }, {
    path: "/nakupny-radca/:slug",
    component: _0ab1f9ad,
    name: "article-slug___sk"
  }, {
    path: "/pobocky/:slug",
    component: _283e4a11,
    name: "branch-slug___sk"
  }, {
    path: "/produkt/:slug",
    component: _5933d334,
    name: "product-slug___sk"
  }, {
    path: "/sprievodca/:slug",
    component: _00886628,
    name: "curatedGuidePage-slug___sk"
  }, {
    path: "/vsetko-o-nakupe/:slug",
    component: _2eb0cc84,
    name: "page-slug___sk"
  }, {
    path: "/*",
    component: _a94df84a,
    name: "all___sk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
